import React from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import {
    Button, Table, TableBody, TableCell, TableHead, TableRow,
    Paper, Typography, Grid, Card, Breadcrumbs, Link,
    List, ListItem, ListItemText, CardContent, Divider, Hidden, BottomNavigationAction, BottomNavigation, TableContainer,
} from "@material-ui/core";
import { withStyles } from '@material-ui/styles';
import { compose } from "recompose";
import * as moment from "moment"
import { useStyle } from "./style";
import { ListAltOutlined, Edit, ContactPhone, List as ListIcon, Comment,Send } from "@material-ui/icons";
// const nonEditableFields = ["Tara Book & sw, per level", "Assessment (pre)", "Assessment (mid)", "Assessment (end)"];
// const editableFields = ["Alexa Echodot device", "ToolBox", "Activity kit (1 activity)", "In person workshop", "Mentorship calls", "Video conference session"];
import * as QuoteAction from "../../../../service/action/quotes";
import ModalComponent from "../../common/modal";
class QuoteView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            "quoteId": null,
            "_id":null,
            "baseline_quote": [],
            "proposed_quote": [],
            "school_name": "",
            "school_location": "",
            "school_contact_name": "",
            "school_contact_phone": "",
            "no_of_students": "",
            "no_of_teachers": "",
            "total_learners": "",
            "total_classes": "",
            "discount_percent_on_total_order": 0,
            "total_order_after_discount": "",
            "final_price_per_student": "",
            "total_discount_value": "",
            "total_amount_per_student": "",
            "gst_amount": "",
            "price_before_tax": "",
            "comments": [],
            "commentInput": "",
            "profit_before_discount": "",
            "profit_after_discount": "",
            "userRole": "",
            "discount_approved_by": "",
            "discount_approved_on": null,
            "discount_percent_on_total_order_error": "",
            "discount_percent_on_total_order_warning": "",
            "navigation": "schoolInfo",
            'sendConfirmModal':false,
            'selectedId':null
        };
        this.handleBottomNav = this.handleBottomNav.bind(this);
    }

    componentDidMount() {
        const me = this;

        let data = me.props.location.state;
        const userRole = me.props.authReducer.auth_user.role;
        if (data !== undefined) {
            me.setState({
                "_id": data._id,
                "quoteId": data.quote_id,
                "baseline_quote": data.baseline_quote,
                "proposed_quote": data.proposed_quote,
                "school_name": data.school_name,
                "school_location": data.school_location,
                "school_contact_name": data.school_contact_name,
                "school_contact_phone": data.school_contact_phone,
                "no_of_students": data.no_of_students,
                "no_of_teachers": data.no_of_teachers,
                "total_learners": data.total_learners,
                "total_classes": data.total_classes,
                "discount_percent_on_total_order": data.discount_percent_on_total_order,
                "total_order_after_discount": data.total_order_after_discount,
                "final_price_per_student": data.final_price_per_student,
                "total_discount_value": data.total_discount_value,
                "total_amount_per_student": data.total_amount_per_student,
                "gst_amount": data.gst_amount,
                "price_before_tax": data.price_before_tax,
                "comments": data.comments,
                "profit_before_discount": data.profit_before_discount,
                "profit_after_discount": data.profit_after_discount,
                "userRole": userRole,
                "discount_approved_by": data.discount_approved_by,
                "discount_approved_on": data.discount_approved_on,
            })
        }
    }

    handleBottomNav(val) {
        this.setState({ "navigation": val })
    }
    render() {
        let classes = this.props.classes
        const auth_user = this.props.authReducer.auth_user;
        if (auth_user === null) {
            return null
        }
        const schoolInfo = (
            <React.Fragment>
                <Typography variant="h6">School Info</Typography>
                <Divider />
                <Table aria-label="table" className={classes.table} >
                    <TableBody>
                        <TableRow className={classes.tableRow}>
                            <TableCell align="left" className={classes.tabledata}>
                                <Typography variant="body1" >School Name</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tableHeader}>
                                {this.state.school_name}
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableRow}>
                            <TableCell align="left" className={classes.tabledata}>
                                <Typography variant="body1" >School Contact Name</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tableHeader}>
                                {this.state.school_contact_name}
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableRow}>
                            <TableCell align="left" className={classes.tabledata}>
                                <Typography variant="body1" >School Contact Phone</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tableHeader}>
                                {this.state.school_contact_phone}
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableRow}>
                            <TableCell align="left" className={classes.tabledata}>
                                <Typography variant="body1">Number of Students</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tableHeader}>
                                {this.state.no_of_students}
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableRow}>
                            <TableCell align="left" className={classes.tabledata}>
                                <Typography variant="body1">Number of Teachers</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tableHeader}>
                                {this.state.no_of_teachers}
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableRow}>
                            <TableCell align="left" className={classes.tabledata}>
                                <Typography variant="body1">Total Learners</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tableHeader}>
                                {this.state.total_learners}
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableRow}>
                            <TableCell align="left" className={classes.tabledata}>
                                <Typography variant="body1">Total Classes</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tableHeader}>
                                {this.state.total_classes}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </React.Fragment>
        )

        const idealAndRecommend = (
            <Card className={classes.card}>
                <Typography variant="h6">Recommended</Typography>
                <TableContainer className={classes.tableContainer}>
                    <Table aria-label="table" className={classes.table} >
                        <TableHead className={classes.mainhead}>
                            <TableRow>
                                <TableCell align="left" className={classes.tableHeader}><Typography >Name</Typography></TableCell>
                                <TableCell align="left" className={classes.tableHeader}><Typography >No of Units</Typography></TableCell>
                                <TableCell align="left" className={classes.tableHeader}><Typography >Final Price</Typography></TableCell>
                                <TableCell align="left" className={classes.tableHeader}><Typography >Remarks</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.baseline_quote.length === 0 && <TableRow><TableCell colSpan={14} style={{ textAlign: "center" }}><Typography >No Records Found</Typography></TableCell></TableRow>}
                            {this.state.baseline_quote.map((row, i) => {
                                return <TableRow key={i} className={classes.tableRow}>
                                    <TableCell align="left" className={classes.tabledata}>{row.name}</TableCell>
                                    <TableCell align="left" className={classes.tabledata}>{row.no_of_unit}</TableCell>
                                    <TableCell align="left" className={classes.tabledata}>{row.final_price}</TableCell>
                                    <TableCell align="left" className={classes.tabledata}>{row.remarks}</TableCell>
                                </TableRow>
                            })}
                            <TableRow className={classes.tableRow} >
                                <TableCell align="left" colSpan="3" className={classes.tabledata}><Typography variant="body1" >Total order value</Typography></TableCell>
                                {this.state.baseline_quote.length !== 0 && <TableCell align="left" className={classes.tabledata}>
                                    {this.state.baseline_quote[this.state.baseline_quote.findIndex(item => item.name === 'Total order value')].final_price}
                                </TableCell>}
                            </TableRow>
                            <TableRow className={classes.tableRow} >
                                <TableCell align="left" colSpan="3" className={classes.tabledata}><Typography variant="body1" >Price per student (inclusive of all taxes)</Typography></TableCell>
                                {this.state.baseline_quote.length !== 0 && <TableCell align="left" className={classes.tabledata}>
                                    {this.state.baseline_quote[this.state.baseline_quote.findIndex(item => item.name === 'Price per student (inclusive of all taxes)')].final_price}
                                </TableCell>}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        )

        const commentBox = (
            <Card className={classes.commentCard}>
                <Typography variant="h6">Comments</Typography>
                <Divider />
                <CardContent className={classes.commentContent}>
                    {this.state.comments.length === 0 && <Typography>No Comments</Typography>}
                    <List>
                        {this.state.comments.map((item, i) => {
                            return <ListItem key={i}>
                                 <ListItemText primary={item.comment} secondary={" " + item.name + " " + moment(item.createdAt).format("MMMM DD YYYY")} />
                            </ListItem>
                        })}
                    </List>
                </CardContent>
            </Card>
        )

        const proposedQuote = (
            <Card className={classes.card}>
                <Typography variant="h6">Proposed</Typography>
                <Table aria-label="table" className={classes.table} >
                    <TableHead className={classes.mainhead}>
                        <TableRow  >
                            <TableCell align="left" className={classes.tableHeader}><Typography >Name</Typography></TableCell>
                            <TableCell align="left" className={classes.tableHeader}><Typography >No of Units</Typography></TableCell>
                            <TableCell align="left" className={classes.tableHeader}><Typography >Final Price</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.proposed_quote.length === 0 && <TableRow><TableCell colSpan={14} style={{ textAlign: "center" }}><Typography >No Records Found</Typography></TableCell></TableRow>}
                        {this.state.proposed_quote.map((row, i) => {
                            return <TableRow key={i} className={classes.tableRow}>
                                <TableCell align="left" className={classes.tabledata}>{row.name}</TableCell>
                                <TableCell align="left" className={classes.tabledata} style={{ paddingLeft: "32px" }}>{row.no_of_unit}</TableCell>
                                <TableCell align="left" className={classes.tabledata}>{row.final_price}</TableCell>
                            </TableRow>
                        })}
                        <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="2" className={classes.tabledata}>
                                <Typography variant="body1">Total order value</Typography>
                            </TableCell>
                            {this.state.proposed_quote.length !== 0 && <TableCell align="left" className={classes.tabledata}>
                                {this.state.proposed_quote[this.state.proposed_quote.findIndex(item => item.name === 'Total order value')].final_price}
                            </TableCell>}
                        </TableRow>
                        <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="2" className={classes.tabledata}><Typography variant="body1" >Price per student (inclusive of all taxes)</Typography></TableCell>
                            {this.state.proposed_quote.length !== 0 && <TableCell align="left" className={classes.tabledata}>
                                {this.state.proposed_quote[this.state.proposed_quote.findIndex(item => item.name === 'Price per student (inclusive of all taxes)')].final_price}
                            </TableCell>}
                        </TableRow>
                    </TableBody>
                </Table>
            </Card>
        )

        const discountCard = (
            <Card className={classes.commentCard}>
                <Table aria-label="table" className={classes.table} >
                    <TableBody>
                        {this.state.userRole !== "User" && <TableRow className={classes.tableRow}>
                            <TableCell align="left" className={classes.tabledata}><Typography variant="body1" >Discount % offered on total order value</Typography></TableCell>
                            <TableCell align="left" className={classes.tabledata}>
                                {this.state.discount_percent_on_total_order}
                            </TableCell>
                            <TableCell align="left" className={classes.tabledata}>{this.state.total_discount_value}</TableCell>
                        </TableRow>}
                        {this.state.userRole !== "User" && <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="2" className={classes.tabledata}>
                                <Typography variant="body1" >Total order value after discount</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tabledata}>
                                {this.state.total_order_after_discount}
                            </TableCell>
                        </TableRow>}
                        <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="2" className={classes.tabledata}>
                                <Typography variant="body1" >Final price per student</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tabledata}>
                                {this.state.final_price_per_student}
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="2" className={classes.tabledata}>
                                <Typography variant="body1" >Price Before Tax</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tabledata}>
                                {this.state.price_before_tax}
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="2" className={classes.tabledata}>
                                <Typography variant="body1" >GST Amounnt</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tabledata}>
                                {this.state.gst_amount}
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="2" className={classes.tabledata}>
                                <Typography variant="body1" >Total Amount Per Student</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tabledata}>
                                {this.state.total_amount_per_student}
                            </TableCell>
                        </TableRow>
                        {this.state.userRole !== "User" && <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="2" className={classes.tabledata}>
                                <Typography variant="body1" >Profit amount (before discount)</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tabledata}>
                                {this.state.profit_before_discount}
                            </TableCell>
                        </TableRow>}
                        {this.state.userRole !== "User" && <TableRow className={classes.tableRow} >
                            <TableCell align="left" colSpan="2" className={classes.tabledata}>
                                <Typography variant="body1" >Profit amount (after discount)</Typography>
                            </TableCell>
                            <TableCell align="left" className={classes.tabledata}>
                                {this.state.profit_after_discount}
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </Card>
        )

        return (
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <Grid container>
                        <Grid item lg={10} sm={8} xs={6}>
                            <Breadcrumbs aria-label="Breadcrumb" className={classes.breadcrumbs}>
                                <Link color="inherit" component={RouterLink} to='/app/quote' >
                                    <Typography>Quote</Typography>
                                </Link>
                                <Typography color="textPrimary" variant="body1">
                                    {this.state.quoteId}
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item lg={1} sm={2} xs={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                endIcon={<Send />}
                                onClick={() => {
                                    this.setState({sendConfirmModal:true})
                                }}
                            >
                                Send
                            </Button>
                        </Grid>
                        <Grid item lg={1} sm={2} xs={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                endIcon={<Edit />}
                                onClick={() => {
                                    console.log("this.props.location.state", this.props.location.state)
                                    this.props.history.push("/app/quote/edit", this.props.location.state)
                                }}
                            >
                                Edit
                        </Button>
                        </Grid>
                    </Grid>
                    <Hidden mdDown implementation="css">
                        {schoolInfo}
                        <Grid container spacing={2}>
                            <Grid item lg={6}>
                                {idealAndRecommend}
                                {commentBox}
                            </Grid>
                            <Grid item lg={6}>
                                {proposedQuote}
                                {discountCard}
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Hidden lgUp implementation="css">
                        {this.state.navigation === "schoolInfo" && schoolInfo}
                        {this.state.navigation === "idealRecommend" && idealAndRecommend}
                        {this.state.navigation === "commentBox" && commentBox}
                        {this.state.navigation === "proposedQuote" && proposedQuote}
                        {this.state.navigation === "proposedQuote" && discountCard}
                    </Hidden>
                    <Hidden lgUp implementation="css">
                        <Grid container>
                            <Grid item sm={12}>
                                <React.Fragment>
                                    <BottomNavigation value={this.state.navigation} className={classes.bottomNavRoot} showLabels>
                                        <BottomNavigationAction label="School Info" value="schoolInfo" icon={<ContactPhone />} classes={{
                                            root: classes.bottomnav,
                                            selected: classes.bottomnavSelected,
                                            wrapper: classes.bottomnav,
                                        }} onClick={() => { this.handleBottomNav("schoolInfo") }}
                                        />
                                        <BottomNavigationAction label="Recommended" value="idealRecommend" icon={<ListAltOutlined />} classes={{
                                            root: classes.bottomnav,
                                            selected: classes.bottomnavSelected,
                                            wrapper: classes.bottomnav,
                                        }} onClick={() => { this.handleBottomNav("idealRecommend") }}
                                        />
                                        <BottomNavigationAction label="Proposed" value="proposedQuote" icon={<ListIcon />} classes={{
                                            root: classes.bottomnav,
                                            selected: classes.bottomnavSelected,
                                            wrapper: classes.bottomnav,
                                        }} onClick={() => { this.handleBottomNav("proposedQuote") }}
                                        />
                                        <BottomNavigationAction label="Comment" value="commentBox" icon={<Comment />} classes={{
                                            root: classes.bottomnav,
                                            selected: classes.bottomnavSelected,
                                            wrapper: classes.bottomnav,
                                        }} onClick={() => { this.handleBottomNav("commentBox") }}
                                        />
                                    </BottomNavigation>
                                </React.Fragment>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <ModalComponent 
                        open={this.state.sendConfirmModal} 
                        title="Confirmation"
                        buttonText="Send"
                        body="Are you sure you want to e-mail this quote to yourself ?"
                        onClose={()=>{
                            this.setState({sendConfirmModal:false})
                        }}
                        onSubmit={()=>{
                            this.props.sendQuote(this.state._id);
                            this.setState({sendConfirmModal:false});
                        }}
                    />
                </Paper>
            </div>
        );
    }
}

export default compose(
    withStyles(useStyle)
)(connect((state, props) => {
    //State to Prop
    return {
        quoteReducer: state.quoteReducer,
        rateCardReducer: state.rateCardReducer,
        authReducer: state.authReducer,
    };
}, {
    sendQuote: QuoteAction.sendQuote
})(QuoteView))
