import {
    GET_QUOTE_END,GET_QUOTE_START, FETCH_QUOTE_END, FETCH_QUOTE_START, ADD_QUOTE_END, ADD_QUOTE_START,
    UPDATE_QUOTE_END, UPDATE_QUOTE_START,SEND_QUOTE_END,SEND_QUOTE_START
} from "../action/quotes";

const initState = {
    quote: null,
    error: null,
    getQuote: false,
    quotes: [],
    fetchProg: false,
    addProg: false,
    updateProg: false,
    totalQuotes:null,
    sendQuoteProg:false

};

const quoteReducer = (state = initState, action) => {
    let payload = action.payload;
    let quotes  =   state.quotes;
    let totalQuotes  =   state.totalQuotes;

    switch (action.type) {
        case GET_QUOTE_START:
            return { ...state, getQuote: true };
        case GET_QUOTE_END:
            let _quote = {};
            let _error = null;
            if (payload.data !== undefined) {
                _quote = payload.data;
            }

            if (payload.error !== undefined) {
                _error = payload.error;
            }
            return { ...state, quote: _quote, error: _error, getQuote: false };

        case FETCH_QUOTE_START:
            return { ...state, fetchProg: true };
        case FETCH_QUOTE_END:
            let _fetchError = null;
            if (payload.data !== undefined) {
                if(payload.page !== undefined){
                    if(payload.page > 1){
                        payload.data.forEach((item)=>{
                            quotes.push(item)
                        }) 
                    }else {
                        quotes = payload.data
                    }
                }
               
            }

            if (payload.count !== undefined) {
                totalQuotes =   payload.count
            }

            if (payload.error !== undefined) {
                _fetchError = payload.error;
            }
            return { ...state, quotes: quotes, error: _fetchError, fetchProg: false,totalQuotes:totalQuotes };

        case ADD_QUOTE_START:
            return { ...state, addProg: true };
        case ADD_QUOTE_END:
            let _addError = null;
            if (payload.error !== undefined) {
                _addError = payload.error;
            }
            return { ...state, error: _addError, addProg: false };

        case UPDATE_QUOTE_START:
            return { ...state, updateProg: true };
        case UPDATE_QUOTE_END:
            let _updateError = null;
            if (payload.error !== undefined) {
                _updateError = payload.error;
            }
            return { ...state, error: _updateError, updateProg: false };

        case SEND_QUOTE_START:
            return { ...state, sendQuoteProg: true };
        case SEND_QUOTE_END:
            let _sendError = null;
            if (payload.error !== undefined) {
                _sendError = payload.error;
            }
            return { ...state, error: _sendError, sendQuoteProg: false };
        default:
            return { ...state };
    }
};
export default quoteReducer;
