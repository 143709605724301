import axios from "axios";
import SnackBarAlertAction,{SnackBarAlertType} from "./snackBarAction";

const url = process.env.REACT_APP_URL;

export const GET_QUOTE_START = "GET_QUOTE_START";
export const GET_QUOTE_END = "GET_QUOTE_END";

export const FETCH_QUOTE_START = "FETCH_QUOTE_START";
export const FETCH_QUOTE_END = "FETCH_QUOTE_END";

export const UPDATE_QUOTE_START = "UPDATE_QUOTE_START";
export const UPDATE_QUOTE_END = "UPDATE_QUOTE_END";

export const ADD_QUOTE_START = "ADD_QUOTE_START";
export const ADD_QUOTE_END = "ADD_QUOTE_END";

export const SEND_QUOTE_START = "SEND_QUOTE_START";
export const SEND_QUOTE_END = "SEND_QUOTE_END";

export function fetch(data) {
    const authToken = window.sessionStorage.getItem("authToken", null);

    return (dispatch) => {
        dispatch({
            type: FETCH_QUOTE_START,
        });
        axios.post(url + "/quote/fetch",data,{
            headers: {
                Authorization: "Bearer " + authToken,
            },
        }).then(function (response) {
            const response_data = response.data;
            if (response.data !== undefined) {
                dispatch({
                    type: FETCH_QUOTE_END,
                    payload: {
                        data: response_data.data.data,
                        count:response_data.data.count,
                        page:data.page
                    },
                });
            } else {
                dispatch({
                    type: FETCH_QUOTE_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Something went wrong!",SnackBarAlertType.ERROR));

            }
        }).catch(function (error) {
            console.log(error);
            if (
                error.response !== undefined &&
                error.response.data !== undefined &&
                error.response.data.message !== undefined
            ) {
                dispatch({
                    type: FETCH_QUOTE_END,
                    payload: {
                        error: error.response.data.message,
                    },
                });
                dispatch(SnackBarAlertAction.addAlert(error.response.data.message,SnackBarAlertType.ERROR));
            } else {
                dispatch({
                    type: FETCH_QUOTE_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Something went wrong!",SnackBarAlertType.ERROR));
            }
        });
    }
}

export function update(id,data) {
    const authToken = window.sessionStorage.getItem("authToken", null);

    return (dispatch) => {
        dispatch({
            type: UPDATE_QUOTE_START,
        });
        axios.put(url + "/quote/"+id,data,{
            headers: {
                Authorization: "Bearer " + authToken,
            },
        }).then(function (response) {
            const response_data = response.data;
            if (response.data !== undefined) {
                dispatch({
                    type: UPDATE_QUOTE_END,
                    payload: {
                        data: response_data.data,
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Quote Updated.",SnackBarAlertType.SUCCESS));
            } else {
                dispatch({
                    type: UPDATE_QUOTE_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Something went wrong!",SnackBarAlertType.ERROR));

            }
        }).catch(function (error) {
            console.log(error);
            if (
                error.response !== undefined &&
                error.response.data !== undefined &&
                error.response.data.message !== undefined
            ) {
                dispatch({
                    type: UPDATE_QUOTE_END,
                    payload: {
                        error: error.response.data.message,
                    },
                });
                dispatch(SnackBarAlertAction.addAlert(error.response.data.message,SnackBarAlertType.ERROR));
            } else {
                dispatch({
                    type: UPDATE_QUOTE_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Something went wrong!",SnackBarAlertType.ERROR));
            }
        });
    }
}

export function add(data) {
    const authToken = window.sessionStorage.getItem("authToken", null);

    return (dispatch) => {
        dispatch({
            type: ADD_QUOTE_START,
        });
        axios.post(url + "/quote/create",data,{
            headers: {
                Authorization: "Bearer " + authToken,
            },
        }).then(function (response) {
            const response_data = response.data;
            if (response.data !== undefined) {
                dispatch({
                    type: ADD_QUOTE_END, 
                    payload: {
                        data: response_data.data,
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Quote created.",SnackBarAlertType.SUCCESS));
            } else {
                dispatch({
                    type: ADD_QUOTE_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Something went wrong!",SnackBarAlertType.ERROR));

            }
        }).catch(function (error) {
            console.log(error);
            if (
                error.response !== undefined &&
                error.response.data !== undefined &&
                error.response.data.message !== undefined
            ) {
                dispatch({
                    type: ADD_QUOTE_END,
                    payload: {
                        error: error.response.data.message,
                    },
                });
                dispatch(SnackBarAlertAction.addAlert(error.response.data.message,SnackBarAlertType.ERROR));
            } else {
                dispatch({
                    type: ADD_QUOTE_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Something went wrong!",SnackBarAlertType.ERROR));
            }
        });
    }
}

export function sendQuote(id) {
    const authToken = window.sessionStorage.getItem("authToken", null);

    return (dispatch) => {
        dispatch({
            type: SEND_QUOTE_START,
        });
        axios.get(url + "/quote/send/"+id,{
            headers: {
                Authorization: "Bearer " + authToken,
            },
        }).then(function (response) {
            if (response.data !== undefined) {
                dispatch({
                    type: SEND_QUOTE_END,
                    payload: {
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Quote sent.",SnackBarAlertType.SUCCESS));
            } else {
                dispatch({
                    type: SEND_QUOTE_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Something went wrong!",SnackBarAlertType.ERROR));

            }
        }).catch(function (error) {
            console.log(error);
            if (
                error.response !== undefined &&
                error.response.data !== undefined &&
                error.response.data.message !== undefined
            ) {
                dispatch({
                    type: SEND_QUOTE_END,
                    payload: {
                        error: error.response.data.message,
                    },
                });
                dispatch(SnackBarAlertAction.addAlert(error.response.data.message,SnackBarAlertType.ERROR));
            } else {
                dispatch({
                    type: SEND_QUOTE_END,
                    payload: {
                        error: "Something went wrong!",
                    },
                });
                dispatch(SnackBarAlertAction.addAlert("Something went wrong!",SnackBarAlertType.ERROR));
            }
        });
    }
}